h1 > strong, a > strong {
  font-size: 1.3rem;
  text-align: center;
}

.outer {
  display: table;
  /* position: absolute; */
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}
